<template>    
	<div class="content-wrapper">
			
			<div v-if="isList" class="content pt-0">
					<bo-page-title />
					<div class="card">
							<div class="card-header bg-white">
									<div class="row align-items-center">
											<div class="col-md-5">
													<div class="form-row align-items-center">
															<div class="col-md-4">
																	<h5 class="card-title font-weight-semibold">Daftar Pasien</h5>
															</div>
															<div class="col-md-8">
																	<div class="form-group mb-0">
																					<div class="input-group">
																							<date-range-picker
																									ref="picker"
																									:locale-data="datePickerConfig.locale"
																									:autoApply="datePickerConfig.autoApply"
																									v-model="dateRange"
																									:opens="'right'"
																									:ranges="datePickerConfig.ranges"
																									@update="updateValues"
																							>
																									<template v-slot:input="picker">
																											{{ picker.startDate | date }} - {{ picker.endDate | date }}
																									</template>
																							</date-range-picker>
																							<div class="input-group-append calendar-group">
																									<span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
																							</div>
																					</div>
																	</div>
															</div>
													</div>
											</div>
									</div>
							</div>
							
							<div class="card-header">
									<div class="row">
											<div class="col-lg-9">
													<div class="row gx-1">
															<div class="col-md">
																	<div class="row gx-1">
																			<div class="col-md-auto">
																					<div class="form-group mb-0">
																							<button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.right ><i class="icon-spinner11"></i></button>
																					</div>
																			</div>                                                                  

																			<div class="col-md-3">
																					<b-form-group class="mb-0">
																							<v-select placeholder=" -- Pilih Jaminan -- " @input="doFill" v-model="filter.status_jaminan"  :options="Config.mr.statusJaminan" label="text" :reduce="v=>v.value"></v-select>
																					</b-form-group>
																			</div>

																			<div class="col-md-2">
																					<b-form-group class="mb-0">
																							<v-select placeholder=" -- Pilih Cara Masuk -- " @input="doFill" v-model="filter.cara_masuk"  :options="Config.mr.caraMasuk" label="text" :reduce="v=>v.value"></v-select>
																					</b-form-group>
																			</div>

																			<div class="col-md-3">
																					<b-form-group class="mb-0">
																							<v-select :disabled="user.levelId != 1" placeholder=" -- Pilih Bangsal -- " @input="doFill" v-model="filter.ranap_bangsal"  :options="mRanapBangsal" label="text" :reduce="v=>v.value"></v-select>
																					</b-form-group>
																			</div>
																																			
																			<div class="col-md-3">
																					<v-select placeholder=" -- Pilih DPJP -- " @input="doFill" v-model="filter.dpjp"  :options="mDPJPUtama" :clearable="true" label="text" :reduce="v=>v.value"></v-select>
																			</div>
																	</div>
															</div>
													</div>
											</div>
											<div class="col-lg-3">
													<div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
															<input class="form-control"
																	v-model="filter.search"
																	@input="doSearch"
																	placeholder="Ketik Nama/No. RM Pasien"
															/>
															<div class="form-control-feedback">
																	<i class="icon-search4 text-indigo"></i>
															</div>
																	
															<b-button
																	class="ml-1 d-inline-flex align-items-center"
																	variant="outline-success"
																	id="resetBtn"
																	@click="doResetData()"
															>Reset</b-button>
													</div>
											</div>
									</div>
							</div>     

									 
							
							<div class="table-responsive">
									<table
											class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
											<thead>
													<tr>
															<th>NO.</th>
															<th>NO. KEDATANGAN</th>
															<th>TANGGAL MASUK</th>
															<th>NAMA/NO KTP</th>
															<th>DOKTER DPJP</th>                                
															<th>NO RM</th>
															<th>RUANGAN</th>
															<th>JENIS RAWAT INAP</th>
															<th>TANGGAL LAHIR</th>   
															<th>USIA</th>    
															<th>PPJP</th>
															<th>STATUS</th>
															<th>AKSI</th>
													</tr>
											</thead>
											
											<tbody v-if="(dataList||[]).length">
													<template v-for="(v,k) in (dataList||[])">
															<tr :key="k" :class="!v.aranr_ppjp && !v.aranr_perawat_pelaksana ? 'table-warning':''">
																	<td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
																	<td>
																			<div class="text-success-700 font-weight-semibold">{{v.aranr_reg_code||"-"}}</div>
																	</td>
																	<td>
																			<div>{{v.aranres_tanggal_periksa | moment("DD MMMM YYYY")}}</div>
																	</td>
																	<td>
																			<div>{{v.ap_fullname||"-"}}<br>
																			{{v.ap_code||"-"}}</div>
																			<small class="text-secondary">Terdaftar {{v.ap_dob | moment("DD MMMM YYYY, HH:mm")}}</small>
																	</td>
																	<td>{{v.bu_full_name||"-"}}</td>
																	<td>
																			<div class="font-weight-semibold">{{v.ap_code||"-"}}</div>
																			<span>dari {{v.aranres_cara_masuk == 'RAJAL'?'POLI': v.aranres_cara_masuk == 'BBL' ? 'BBL':'UGD'}}</span>
																			<div class="text-success-700 font-weight-semibold">
																					{{v.no_reg||"-"}}                                    
																			</div>
																	</td>
																	<td>
																			<div class="font-weight-semibold">{{v.mrank_name||"-"}}</div>
																			<small><span>{{v.mranb_name||"-"}}</span> - <span>{{v.mrankel_name||"-"}}</span></small>
																			<span class="text-secondary" v-if="v.mranbed_name"> - {{v.mranbed_name || "-"}}</span>
																	</td>
																	<td>
																			<div class="font-weight-semibold">{{getConfigDynamic(Config.mr.jenisRawatInap,v.aranres_jenis_ranap)||"-"}}</div>
																	</td>
																	<td>
																		<div class="font-weight-semibold" v-if="v.ap_dob">{{v.ap_dob | moment("DD MMMM YYYY") }}</div>
																		<span v-else> - </span>
																	</td>
																	<td>
																			<div class="font-weight-semibold">
																					{{v.ap_usia_with_ket || "-"}}
																			</div>
																	</td>
																	<td>
																			{{v.nama_ppjp||"-"}}
																	</td>
																	<td>
																			<div v-if="!v.aranr_ppjp && !v.aranr_perawat_pelaksana" class="badge badge-warning mb-1">Tugaskan PPJP</div>
																			<div v-else-if="v.aranr_ppjp && !v.aranr_perawat_pelaksana" class="badge badge-warning mb-1">Tugaskan Perawat Pelaksana</div>
																			<div v-else :class="`badge ${v.arans_color} mb-1`">
																					{{v.arans_name||"-"}}
																			</div>
																	</td>
																	<td>
																			<a href="javascript:;" v-if="moduleRole('tugaskan_ppjp')" @click="tugasKanPPJP(v)" class="btn btn-icon rounded-round btn-sm alpha-warning border-warning text-warning-800" v-b-tooltip.hover.right :title="!v.aranr_ppjp ? 'Tugaskan PPJP' : 'Ganti PPJP'">
																			<i class="icon-user-check"></i>
																			</a>
																			
																			<a href="javascript:;" v-if="((v.aranr_ppjp || user.levelId == 1)) && moduleRole('tugaskan_ppjp')" @click="tugasKanPerawatPelaksana(v)" class="btn btn-icon rounded-round btn-sm alpha-warning border-warning text-warning-800" v-b-tooltip.hover.right :title="v.aranr_perawat_pelaksana ? 'Ganti Perawat pelaksana' : 'Tugaskan Perawat pelaksana'">
																					<i class="fa-solid fa-user-nurse"></i>
																			</a>

																			<a href="javascript:;" v-if="v.aranr_is_ttd_intra == 'Y' && moduleRole('tandatangani_transfer_intra')" class="btn btn-icon rounded-round btn-sm alpha-success border-success text-success-800" v-b-tooltip.hover.right @click="checkTTDIntra(v)" title="Tandatangani Form Transfer Intra">
																			<i class="icon-pencil4"></i>
																			</a>
																			<a href="javascript:;" @click="doOpenRiwayat(v)" v-if="moduleRole('lihat_dokumen_kajian')" class="btn btn-icon rounded-round btn-sm alpha-info border-info text-info-800" data-popup="tooltip" v-b-tooltip.hover.right title="Lihat Dokumen Kajian">
																			<i class="icon-file-text2"></i>
																			</a>
																	</td>

															</tr>
													</template>
											</tbody>
											<tbody v-if="data.data.length == 0">
													<tr>
															<td colspan="99">
															<div class="text-center">
																	<h4 align="center"><span v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian tidak ditemukan</span>
																	<span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
															</div>
															</td>
													</tr>
											</tbody>
											<tbody v-if="!data.data">   
													<tr>
															<td colspan="99"><div class="skeletal-comp"></div></td>
													</tr>
													<tr>
															<td colspan="99"><div class="skeletal-comp"></div></td>
													</tr>
													<tr>
															<td colspan="99"><div class="skeletal-comp"></div></td>
													</tr>
											</tbody>
									</table>

									
									<div :class="(dataList||[]).length && !loadingTable && dataList ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
											<a href="javascript:;" data-scroll="left" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
											<i class="icon-chevron-left"></i>
											</a>
											<a href="javascript:;" data-scroll="right" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
											<i class="icon-chevron-right"></i>
											</a>
									</div>
							</div>
							
							<b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">     <b-pagination
									class="mb-0"
									v-model="pageNo"
									:per-page="data.per_page"
									:total-rows="data.total"
							/>
							</b-card-footer>

					</div>

					<validation-observer ref="VFormSubmitTransferIntra">
							<b-modal 
									v-model="ttdFormIntra"
									@ok.prevent="submitTransferIntra"
									size="xl"
									no-close-on-backdrop
									class="text-center"
									:title="'Tandatangani Dokumen'" 
							>

							</b-modal>
					</validation-observer>

					<validation-observer ref="VFormSubmitPPJP">
							<b-modal 
									v-model="openPPJP"
									@ok.prevent="submitPPJP"
									size="lg"
									no-close-on-backdrop
									class="text-center"
									:title="'Tugaskan PPJP'" 
							>
									<table class="table table-sm table-bordered">
											<thead>
													<tr>
													<th colspan="6">Informasi Pasien</th>
													</tr>
											</thead>
											<tbody>
													<tr>
													<td colspan="2">
															<div class="result_tab">
																	<h4>Nama</h4>
																	<p>{{rowEdit.ap_fullname||"-"}}</p>
															</div>
													</td>
													<td colspan="2">
															<div class="result_tab">
																	<h4>Tanggal Lahir / Umur</h4>
																	<p>{{rowEdit.ap_dob | moment("DD MMMM YYYY") }} / {{rowEdit.ap_usia}} Tahun</p>
															</div>
													</td>
													<td colspan="2">
															<div class="result_tab">
															<h4>Jenis Kelamin</h4>
															<p>{{rowEdit.ap_gender == 1 ? "Laki-Laki":"Perempuan"}}</p>
															</div>
													</td>
													</tr>
													<tr>
													<td colspan="2">
															<div class="result_tab">
															<h4>Ruangan</h4>
															<p>{{rowEdit.mranb_name||"-"}}</p>
															</div>
													</td>
													<td colspan="2">
															<div class="result_tab">
															<h4>Kamar / No. Tempat Tidur / Kelas</h4>
															<p>{{rowEdit.mranbed_name||"-"}} / {{rowEdit.mrankel_name||"-"}}</p>
															</div>
													</td>
													<td colspan="2">
															<div class="result_tab">
															<h4>Kelas Titipan</h4>
															<p>-</p>
															</div>
													</td>
													</tr>
													<tr>
													<td colspan="2">
															<div class="result_tab">
															<h4>Jenis Cara Bayar</h4>
															<p>{{getConfigDynamic(Config.mr.statusJaminan,rowEdit.aranres_jaminan)||"-"}}</p>
															</div>
													</td>
													<td colspan="2">
															<div class="result_tab">
															<h4>Alergi</h4>
																	<ul v-if="(rowEdit.alergi||[]).length" class="pl-3">
																			<li v-for="(v,k) in (rowEdit.alergi||[])" :key="k+'alergi'">
																					{{v.name||"-"}} - <span class="font-weight-semibold">{{v.jenis||"-"}}</span>
																			</li>
																	</ul>
																	<span v-else>Tidak ada Alergi</span>
															</div>
													</td>
													<td colspan="2">
															<div class="result_tab">
															<h4>Diagnosa Masuk</h4>
															<p>{{rowEdit.diagnosa||"-"}}</p>
															</div>
													</td>
													</tr>
											</tbody>

											<thead>
													<tr>
													<th colspan="6">Penugasan PPJP</th>
													</tr>
											</thead>
											<tbody>
													<tr>
													<td colspan="3" width="50%">
															<div>
															<label for="selPppjp">Pilih Perawat Penanggung jawab Pasien (PPJP)</label>
															<!-- :disabled="rowEdit.aranr_ppjp_at?true:false" -->
															<v-select placeholder=" -- Pilih Perawat Penanggung jawab Pasien -- " 
															v-model="rowEdit.aranr_ppjp" :clearable="true" :options="mPPJP" label="text" :reduce="v=>v.value">
																	<template slot="selected-option" slot-scope="option">
																	<span :title="option.text+' - menangani '+option.pasien + ' Pasien'" v-b-tooltip.hover.right>{{ option.text }}</span>
																	</template>
																	<template slot="option" slot-scope="option">
																	<span :title="option.text+' - menangani '+option.pasien + ' Pasien'" v-b-tooltip.hover.right>{{ option.text }} - menangani 
																			<span class="font-weight-semibold"> {{option.pasien}} Pasien </span></span>
																	</template>
															</v-select>
															</div>
															<VValidate 
																	:name="'Perawat Penanggung Jawab Pasien'" 
																	v-model="rowEdit.aranr_ppjp" 
																	:rules="{required : 1}"
															/>
													</td>
													<td colspan="3" width="50%">
															<div></div>
													</td>
													</tr>
											</tbody>
									</table>
							</b-modal>
					</validation-observer>

					<b-modal size="xl" title="Tanda Tangan Form Transfer Intra" id="ttd_transfer_intra" hide-footer>
							<b-overlay :show="loadingTTDIntra">
									<validation-observer ref="VFormTTD" v-slot="{handleSubmit}">
									<b-form @submit.prevent="handleSubmit(doSubmitTTD)">  
											<div class="form-underlying card-body p-3">
											<div class="card mb-2">
													<div class="card-header p-0" role="tab">
													<a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra1">
															<div class="d-flex align-items-center">
															<div class="label_code m-0">
																	<h2>S</h2>
															</div>
															<strong class="ml-2">Situation</strong>
															</div>
													</a>
													</div>
													<div class="wrap_line collapse show" id="formIntra1">
													<table class="table table-bordered">
															<tr>
															<td width="33%">
																	<div class="result_tab">
																	<h4>Nama</h4>
																	<p>{{dataModal.ap_fullname||"-"}}</p>
																	</div>
															</td>
															<td width="33%">
																	<div class="result_tab">
																	<h4>No. Rekam Medis</h4>
																	<p>{{dataModal.ap_code||"-"}}</p>
																	</div>
															</td>
															<td width="33%">
																	<div class="result_tab">
																	<h4>NIK</h4>
																	<p>{{dataModal.ap_nik||"-"}}</p>
																	</div>
															</td>
															</tr>
							
															<tr>
															<td>
																	<div class="result_tab">
																	<h4>Tanggal Lahir</h4>
																	<p v-if="dataModal.ap_dob">{{dataModal.ap_dob | moment("DD MMMM YYYY")}}</p>
																	<p v-else> - </p>
																	</div>
															</td>
															<td>
																	<div class="result_tab">
																	<h4>DPJP</h4>
																	<p>{{dataModal.dpjp_name||"-"}}</p>
																	</div>
															</td>
															<td>
																	<div class="result_tab">
																	<h4>Tanggal Datang</h4>
																	{{ dataModal.artipr_tanggal_datang | moment('DD MMMM YYYY, HH:mm') }}
																	</div>
															</td>
															</tr>
							
															<tr>
															<td>
																	<div class="result_tab">
																	<h4>Diagnosa</h4>
																	<p>{{dataModal.artipr_diagnosa||"-"}}</p>
																	</div>
															</td>
															<td>
																	<div class="result_tab">
																	<h4>Kondisi Saat Ini</h4>
																	{{ dataModal.artipr_kondisi_saat_ini }}
																	</div>
															</td>
															<td>
																	<div class="result_tab">
																	<h4></h4>
																	<p></p>
																	</div>
															</td>
															</tr>
							
															<tr>
															<td>
																	<div class="result_tab">
																	<h4>Dari Ruang</h4>
																	<p>{{dataModal.ruang_dari||"-"}}</p>
																	</div>
															</td>
															<td>
																	<div class="result_tab">
																	<h4>Ke Ruang</h4>
																	<p>{{dataModal.ruang_ke||"-"}}</p>
																	</div>
															</td>
															<td>
																	<div class="result_tab">
																	<h4>Tanggal Pindah</h4>
																	{{ dataModal.artipr_tanggal_pindah | moment('DD MMMM YYYY, HH:mm')}}
																	</div>
															</td>
															</tr>
													</table>
													</div>
											</div>

											<div class="card mb-2">
													<div class="card-header p-0" role="tab">
													<a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra2">
															<div class="d-flex align-items-center">
															<div class="label_code m-0">
																	<h2>B</h2>
															</div>
															<strong class="ml-2">Background</strong>
															</div>
													</a>
													</div>
													<div class="wrap_line collapse show" id="formIntra2">
													<div class="row">
															<div class="col-md-8">
															<div class="row">
																	<div class="col-md-6">
																	<div class="form-group">
																			<label for=""><strong>Keluhan Utama</strong></label>
																			<p>{{ dataModal.artipr_keluhan_utama }}</p>
																	</div>
																	</div>

																	<div class="col-md-6">
																	<div class="form-group">
																			<label for=""><strong>Riwayat Penyakit</strong></label>
																			<p>{{ dataModal.artipr_riwayat_penyakit }}</p>
																	</div>
																	</div>

																	<div class="col-md-6">
																	<div class="form-group">
																			<label for=""><strong>Intervensi yang Telah Dilakukan</strong></label>
																			<p>{{ dataModal.artipr_background_intervensi }}</p>
																	</div>  
																	</div>

																	<div class="col-md-6">
																	<div class="form-group">
																			<label for="asuhanPembedahan">Riwayat Pembedahan </label>
																			<P>{{ dataModal.artipr_riwayat_pembedahan }}</P>
																	</div>
																	</div>

																	<div class="col-md-6">
																	<div class="form-group">
																			<label for=""><strong>Obat-obatan yang Diberikan</strong></label>
																			<p>{{ dataModal.artipr_obat_obatan_yang_diberikan }}</p>
																	</div>
																	</div>

																	<div class="col-md-6">
																	<div class="form-group">
																			<label for=""><strong>Cairan Parenteral yang Diberikan</strong></label>
																			<p>{{ dataModal.artipr_cairan_parental_yang_diberikan }}</p>
																	</div>
																	</div>

																	<div class="col-md-6">
																	<div class="form-group">
																			<label for=""><strong>Riwayat Alergi</strong></label>
																			<p>{{ dataModal.artipr_riwayat_alergi }}</p>
																	</div>
																	</div>

															</div>
															</div>
															<div class="col-md-4">
															<div class="form-group">
																	<label for=""><strong>Tindakan Invasif</strong></label>
																	<table class="table table-sm table-bordered">
																	<thead>
																			<tr>
																			<th width="48">#</th>
																			<th>Tindakan</th>
																			</tr>
																	</thead>
																	<tbody>
																			<tr v-for="(v,k) in (dataModal.artipr_tindakan_invasif||[])" :key="k">
																			<td>{{k+1}}</td>
																			<td>
																					{{ dataModal.artipr_tindakan_invasif[k] }}
																			</td>
																			</tr>
																			<tr v-if="!(dataModal.artipr_tindakan_invasif||[]).length">
																			<td colspan="3" class="text-center">
																					Tidak ada Data
																			</td>
																			</tr>
																	</tbody>
																	</table>
															</div>
															</div>
													</div>
													</div>
											</div>

											<div class="card mb-2">
													<div class="card-header p-0" role="tab">
													<a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra3">
															<div class="d-flex align-items-center">
															<div class="label_code m-0">
																	<h2>A</h2>
															</div>
															<strong class="ml-2">Assessment</strong>
															</div>
													</a>
													</div>

													<div class="wrap_line collapse show" id="formIntra3">
													<div class="card border shadow-0">
															<div class="bg-light card-header py-2">
															<h6 class="card-title mb-0 font-weight-semibold">Kondisi Pasien Terkini</h6>
															</div>
															<div class="card-body">
															<table class="table table-bordered">
																	<tr>
																	<td>
																			<div class="result_tab">
																			<h4>Skala Nyeri</h4>
																			<p>{{dataModal.artipr_skala_nyeri_value||0}} 
																					<span v-if="dataModal.artipr_skala_nyeri_value == 0" class="border-left ml-2 pl-2 font-weight-semibold">Santai dan Nyaman</span>
																					<span v-else-if="dataModal.artipr_skala_nyeri_value >= 1 && dataModal.artipr_skala_nyeri_value < 4" class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan Ringan</span>
																					<span v-else-if="dataModal.artipr_skala_nyeri_value >= 4 && dataModal.artipr_skala_nyeri_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
																					<span v-else class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan/Nyeri Parah</span>
																					<br />(Numeric Rating Scale)
																			</p>
																			</div>
																	</td>
																	<td>
																			<div class="result_tab">
																			<h4>Tingkat Kesadaran</h4>
																			{{ dataModal.artipr_tingkat_kesadaran_value }}
																			<p>
																					{{ dataModal.artipr_tingkat_kesadaran_value }}
																					<template v-if="dataModal.umur_hari <= 28">
																					<span v-if="dataModal.artipr_tingkat_kesadaran_value >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmetris</span> 
																					<span v-else-if="dataModal.artipr_tingkat_kesadaran_value >= 12 && dataModal.artipr_tingkat_kesadaran_value <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
																					<span v-else-if="dataModal.artipr_tingkat_kesadaran_value >= 10 && dataModal.artipr_tingkat_kesadaran_value <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
																					<span v-else-if="dataModal.artipr_tingkat_kesadaran_value >= 7 && dataModal.artipr_tingkat_kesadaran_value <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somnolen</span>
																					<span v-else-if="dataModal.artipr_tingkat_kesadaran_value >= 5 && dataModal.artipr_tingkat_kesadaran_value <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
																					<span v-else-if="dataModal.artipr_tingkat_kesadaran_value >= 4 && dataModal.artipr_tingkat_kesadaran_value <= 4" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
																					<span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
																					</template>
																					<template v-else-if="dataModal.umur_hari > 28">
																					<span v-if="dataModal.artipr_tingkat_kesadaran_value >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmetris</span> 
																					<span v-else-if="dataModal.artipr_tingkat_kesadaran_value >= 12 && dataModal.artipr_tingkat_kesadaran_value <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
																					<span v-else-if="dataModal.artipr_tingkat_kesadaran_value >= 10 && dataModal.artipr_tingkat_kesadaran_value <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
																					<span v-else-if="dataModal.artipr_tingkat_kesadaran_value >= 7 && dataModal.artipr_tingkat_kesadaran_value <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somnolen</span>
																					<span v-else-if="dataModal.artipr_tingkat_kesadaran_value >= 5 && dataModal.artipr_tingkat_kesadaran_value <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
																					<span v-else-if="dataModal.artipr_tingkat_kesadaran_value >= 4 && dataModal.artipr_tingkat_kesadaran_value <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
																					<span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
																					</template>
																					<br />(GCS)
																			</p>
																			</div>
																	</td>
																	<td>
																			<div class="result_tab">
																			<h4>Risiko Jatuh</h4>
																			<p>{{dataModal.artipr_resiko_jatuh_value||0}} 
																					<span v-if="dataModal.artipr_resiko_jatuh_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Rendah</span>
																					<span v-else-if="dataModal.artipr_resiko_jatuh_value >= 7 && dataModal.artipr_resiko_jatuh_value <= 11" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Sedang</span>
																					<span v-else-if="dataModal.artipr_resiko_jatuh_value >= 12" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Tinggi</span>
																					<br />
																					<span v-if="dataModal.ap_usia <= 18">(Kajian Humpty Dumpty)</span>
																					<span v-else-if="dataModal.ap_usia > 18 && dataModal.ap_usia <= 60">(Kajian Morse Fall Scale)</span>
																					<span v-else>(Kajian Skala Ontario Modified Stratify - Sydney Scoring)</span>
																			</p>
																			</div>
																	</td>
																	</tr>
																	<tr>
																	<td>
																			<div class="result_tab">
																			<h4>Tekanan Darah</h4>
																			<p>{{dataModal.artipr_tekanan_darah_min||"-"}}/{{dataModal.artipr_tekanan_darah_max||"-"}} mmHG</p>
																			</div>
																	</td>
																	<td>
																			<div class="result_tab">
																			<h4>Nadi</h4>
																			<p>{{dataModal.artipr_nadi||"-"}}x per menit</p>
																			</div>
																	</td>
																	<td>
																			<div class="result_tab">
																			<h4>Suhu</h4>
																			<p>{{dataModal.artipr_suhu||"-"}} &deg;C</p>
																			</div>
																	</td>
																	</tr>
																	<tr>
																	<td>
																			<div class="result_tab">
																			<h4>Respiration Rate</h4>
																			<p>{{dataModal.artipr_respiration||"-"}}x per menit</p>
																			</div>
																	</td>
																	<td>
																			<div class="result_tab">
																			<h4>SpO2</h4>
																			<p>{{dataModal.artipr_spo2||"-"}}</p>
																			</div>
																	</td>
																	<td>
																			<div class="result_tab">
																			<h4></h4>
																			<p></p>
																			</div>
																	</td>
																	</tr>
															</table>
															</div>
													</div>
													<div class="row">
															<div class="col-md-5">
															<div class="form-group">
																	<label for=""><strong>Pemeriksaan Lab</strong></label>
																	<p>{{ dataModal.artipr_lab }}</p>
															</div>
															</div>
															<div class="col-md-5">
															<div class="form-group">
																	<label for=""><strong>Radiologi</strong></label>
																	<p>{{ dataModal.artipr_radiologi }}</p>
															</div>
															</div>
													</div>
													</div>
											</div>

											<div class="card mb-2">
													<div class="card-header p-0" role="tab">
													<a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra4">
															<div class="d-flex align-items-center">
															<div class="label_code m-0">
																	<h2>R</h2>
															</div>
															<strong class="ml-2">Recommendation</strong>
															</div>
													</a>
													</div>
													<div class="wrap_line collapse show" id="formIntra4">
													<div class="row">
															<div class="col-md-6">
															<div class="form-group">
																	<label for="">Tindakan yang Perlu Dilanjutkan</label>
																	<p>{{ dataModal.artipr_tindakan }}</p>
															</div>
															</div>
															<div class="col-md-6">
															<div class="form-group">
																	<label for="">Usulan Kolaborasi</label>
																	<p>{{ dataModal.artipr_usulan_kolab }}</p>
															</div>
															</div>
													</div>
													</div>
											</div>

											<div class="card mb-2">
													<b-row>
													<b-col md="4">
															<b-form-group>
															<label>Penandatangan 1</label>
															<b-form-input v-model="dataModal.artipr_ttd_name_1"></b-form-input>
															<VValidate name="Nama Penandatangan" v-model="dataModal.artipr_ttd_name_1" :vid="'nama_ttd' + 1" rules="required" />
															</b-form-group>
															<div class="form-group">
															<div class="signing-element" data-target-id="ttd1">
																	<VueSignaturePad  
																	class="sign-container"
																	width="100%"
																	height="160px"
																	ref="ttd1"
																	/>
																	<div class="mt-2">
																	<button type="button" @click="undoTTD('ttd1','artipr_ttd_1', 'dataModal')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
																			<b><i class="icon-cross3"></i></b>
																			<span>Clear</span>
																	</button>
																	</div>
															</div>
															</div>
													</b-col>
													<b-col md="4">
															<label>Penandatangan 2</label>
															<b-form-group>
															<b-form-input v-model="dataModal.artipr_ttd_name_2"></b-form-input>
															<VValidate name="Nama Penandatangan" v-model="dataModal.artipr_ttd_name_2" :vid="'nama_ttd' + 2" rules="required" />
															</b-form-group>
															<div class="form-group">
															<div class="signing-element" data-target-id="ttd2">
																	<VueSignaturePad  
																	class="sign-container"
																	width="100%"
																	height="160px"
																	ref="ttd2"
																	/>
																	<div class="mt-2">
																	<button type="button" @click="undoTTD('ttd2','artipr_ttd_2', 'dataModal')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
																			<b><i class="icon-cross3"></i></b>
																			<span>Clear</span>
																	</button>
																	</div>
															</div>
															</div>
													</b-col>
													</b-row>
											</div>
											<b-row class="mt-3">
													<b-col md=12 class="text-right">
													<b-button type="button" class="mr-2" variant="secondary" @click="closeModalTTD">Tutup</b-button>
													<b-button type="button" variant="primary" @click="doSubmitTTD">Simpan</b-button>
													</b-col>
											</b-row>
											</div>
									</b-form>
									</validation-observer>
							</b-overlay>
					</b-modal>

					<b-modal id="list_ttd_intra" title="Pilih Tanda Tangan Form Transfer Intra" hide-footer>
							<div class="row">
									<div class="col-md-6 mb-2" v-if="dataModalCheck.show_ttd_intra_ranap == 'Y'"><a href="javascript:;" @click.prevent="openTTDIntra(dataModal)" class="btn_cta_persalinan"> Form Tranfser Intra Ranap </a></div>
									<div class="col-md-6 mb-2" v-if="dataModalCheck.transferBedah"><a href="javascript:;" @click.prevent="TTDBedahRanap(dataModalCheck.transferBedah)" class="btn_cta_persalinan"> Form Tranfser Intra Bedah Ranap </a></div>
							</div>
					</b-modal>

					<b-modal id="ttd_bedah_ranap" size="xl" title="Tanda Tangan Form Bedah Ke Ranap" hide-footer>
							<ModalTTDBedahRanap v-if="dataModal.arantbr_id" :arano_id="dataModal.arano_id" :arantbr_id="dataModal.arantbr_id" @closeModal="closeModalTTDBedahRanap" @successSubmit="successTTDBedahRanap" @resize="resize" />
					</b-modal>
					

					<validation-observer ref="VFormSubmitPerawatPelaksana">
					<b-modal v-model="openPerawatPelaksana" @ok.prevent="submitPerawatPelaksana" size="lg" no-close-on-backdrop class="text-center" :title="'Tugaskan Perawat Pelaksana'">
							<table class="table table-sm table-bordered">
							<thead>
									<tr>
									<th colspan="6">Informasi Pasien</th>
									</tr>
							</thead>
							<tbody>
									<tr>
									<td colspan="2">
											<div class="result_tab">
											<h4>Nama</h4>
											<p>{{rowEdit.ap_fullname||"-"}}</p>
											</div>
									</td>
									<td colspan="2">
											<div class="result_tab">
											<h4>Tanggal Lahir / Umur</h4>
											<p>{{rowEdit.ap_dob | moment("DD MMMM YYYY") }} / {{rowEdit.ap_usia}} Tahun</p>
											</div>
									</td>
									<td colspan="2">
											<div class="result_tab">
											<h4>Jenis Kelamin</h4>
											<p>{{rowEdit.ap_gender == 1 ? "Laki-Laki":"Perempuan"}}</p>
											</div>
									</td>
									</tr>
									<tr>
									<td colspan="2">
											<div class="result_tab">
											<h4>Ruangan</h4>
											<p>{{rowEdit.mranb_name||"-"}}</p>
											</div>
									</td>
									<td colspan="2">
											<div class="result_tab">
											<h4>Kamar / No. Tempat Tidur / Kelas</h4>
											<p>{{rowEdit.mranbed_name||"-"}} / {{rowEdit.mrankel_name||"-"}}</p>
											</div>
									</td>
									<td colspan="2">
											<div class="result_tab">
											<h4>Kelas Titipan</h4>
											<p>-</p>
											</div>
									</td>
									</tr>
									<tr>
									<td colspan="2">
											<div class="result_tab">
											<h4>Jenis Cara Bayar</h4>
											<p>{{getConfigDynamic(Config.mr.statusJaminan,rowEdit.aranres_jaminan)||"-"}}</p>
											</div>
									</td>
									<td colspan="2">
											<div class="result_tab">
											<h4>Alergi</h4>
											<ul v-if="(rowEdit.alergi||[]).length" class="pl-3">
													<li v-for="(v,k) in (rowEdit.alergi||[])" :key="k+'alergi'">
													{{v.name||"-"}} - <span class="font-weight-semibold">{{v.jenis||"-"}}</span>
													</li>
											</ul>
											<span v-else>Tidak ada Alergi</span>
											</div>
									</td>
									<td colspan="2">
											<div class="result_tab">
											<h4>Diagnosa Masuk</h4>
											<p>{{rowEdit.diagnosa||"-"}}</p>
											</div>
									</td>
									</tr>
							</tbody>

							<thead>
									<tr>
									<th colspan="6">Penugasan Perawat Pelaksana</th>
									</tr>
							</thead>

							<tbody>

									<tr>
									<td colspan="3" width="40%">
											<div>
											<label for="selPppjp">Pilih Perawat Pelaksana</label>
											<v-select placeholder=" -- Pilih Perawat Pelaksana -- " class="vs__multiple" v-model="rowEdit.aranr_perawat_pelaksana" multiple :clearable="true" :options="mPPJP" label="text" :reduce="v=>v.value">
													<template slot="selected-option" slot-scope="option">
													<span>{{ option.text }}</span>
													</template>
													<template slot="option" slot-scope="option">
													<span>{{ option.text }} - menangani
															<span class="font-weight-semibold"> {{option.pasien}} Pasien </span></span>
													</template>
											</v-select>
											</div>
											<VValidate :name="'Perawat Pelaksana'" v-model="rowEdit.aranr_perawat_pelaksana" :rules="{required : 1}" />
									</td>
									<td colspan="3" width="50%">
											<div>
											<label for="selPppjp">PPJP</label>
											<p class="font-weight-semibold">{{rowEdit.nama_ppjp||"-"}}</p>
											</div>
									</td>
									</tr>
							</tbody>

							</table>
					</b-modal>
					</validation-observer>
			</div>

			
			<b-modal v-model="isOpenModalHistory" :title="'Riwayat Rekam Medis'" size="xl" ok-only ok-title="Tutup">
					<b-tabs nav-class="nav-tabs-bottom nav-justified mt-2 mb-0">
							<b-tab title="UGD">
							<HistoryUGD :dataProps="dataUGD" :pasienId="rowEdit.aranr_ap_id" />
							</b-tab>
							<b-tab title="Rawat Jalan">
							<HistoryRawatJalan :dataProps="dataPoli" :pasienId="rowEdit.aranr_ap_id" />
							</b-tab>
					</b-tabs>
			</b-modal>

			
	</div>
</template>

<script>
	import GlobalVue from '@/libs/Global.vue'
	import DateRangePicker from 'vue2-daterange-picker'
	import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
	import ModalTTDBedahRanap from '../RanapSerahTerimaPasien/ModalTTDBedahRanap.vue'
	const moment = require('moment')

	// import Datepicker from 'vuejs-datepicker'


	const _ = global._
	import $ from 'jquery'
	import Gen from '@/libs/Gen.js'
	import HistoryUGD from '@/components/Ranap/HistoryUGD.vue'
	import HistoryRawatJalan from '@/components/Ranap/HistoryRawatJalan.vue'

	export default {
			extends: GlobalVue,
			components:{
					DateRangePicker,
					ModalTTDBedahRanap,
					HistoryUGD,
					HistoryRawatJalan,
			},
			computed:{
					isDetail(){
							return this.$route.query.isDetail
					},
					isDoc(){
							return this.$route.query.isDoc
					},
					passToSub(){
							return {
									Config:this.Config,
									mrValidation: this.mrValidation,
									rowPasien: this.rowPasien,
									isShowBangsal: this.isShowBangsal,
									infoBangsal: this.infoBangsal,
									katalogBangsal: this.katalogBangsal
							}
					},
			},
			data(){
					return {
							idKey:'aranres_id',
							datePickerConfig: {
									startDate: new Date(moment().subtract(6, 'days')),
									endDate: new Date(),
									autoApply: true,
									ranges: {
											'Hari Ini': [new Date(), new Date()],
											'7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
											'30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
									},
									applyClass: 'btn-sm btn-primary',
									cancelClass: 'btn-sm btn-light',
									locale: {
											applyLabel: 'Terapkan',
											cancelLabel: 'Batal',
											direction: 'ltr',
											format: 'mm/dd/yyyy',
											separator: ' - ',
									}
							},
							dateRange: {
									startDate: new Date(moment().subtract(6, 'days')),
									endDate: new Date(),
							},

							rowEdit : {},
							rowPasien : {},
							rowAdditional: {},
							mRanapBangsal : [],
							mPPJP: [],
							openPPJP: false,
							mDPJPUtama: [],

							ttdFormIntra: false,
							dataModal: {},
							loadingTTDIntra: false,

							dataModalCheck: {},
							openPerawatPelaksana: false,

							isOpenModalHistory: false,
							dataPoli: {},
							dataUGD: {}
					}
			},
			mounted(){
					this.filter.status_jaminan = 'ALL'
					this.filter.cara_masuk = 'ALL'
					this.filter.ranap_bangsal = this.user.bu_bangsal_id
					
					this.doConvertDate()      
					this.apiGet()
					
					if(this.isList){
							this.apiGetResume()
					}
							
					setTimeout(()=>{
							this.setSlide()
					},1000)
			},
			methods: {
					doOpenRiwayat(row){
							this.rowEdit = row
							this.loadingOverlay = true
							Gen.apiRest(
									"/do/" + 'RanapPasienPerawatan', {
									data: {
											type: "get-riwayat-poli",
											id: this.rowEdit.aranr_ap_id
									}
									},
									"POST"
							).then(res => {
									let resp = res.data
									this.dataPoli = resp.dataPoli
							})

							Gen.apiRest(
									"/do/" + 'RanapPasienPerawatan', {
									data: {
											type: "get-riwayat-ugd",
											id: this.rowEdit.aranr_ap_id
									}
									},
									"POST"
							).then(res => {
									let resp = res.data
									this.loadingOverlay = false
									this.isOpenModalHistory = true
									this.dataUGD = resp.dataUGD
							})
					},
					resize(){
							setTimeout(()=>{
									console.log('resize')
									$(".sign-container canvas").attr("width",400)
									$(".sign-container canvas").attr("height",160)
							},200)
					},
					closeModalTTDBedahRanap(){
							this.$set(this, 'dataModal', {})
							this.$bvModal.hide('ttd_bedah_ranap')
					},
					TTDBedahRanap(val){
							this.$set(this.dataModal, 'arantbr_id', val.arantbr_id)
							this.$set(this.dataModal, 'arano_id', val.arano_id)
							this.$bvModal.show('ttd_bedah_ranap')
					},
					successTTDBedahRanap(){
							this.$bvModal.hide('list_ttd_intra')
							this.closeModalTTDBedahRanap()
							this.apiGet()
					},
					checkTTDIntra(row){
							this.loadingTTDIntra = true
							this.$bvModal.show('list_ttd_intra')

							Gen.apiRest('/get/RanapSerahTerimaPasien/check-need-ttd', {
									params: {
											aranr_id: row.aranr_id,
									}
							}).then(res => {
									this.loadingTTDIntra = false
									this.dataModal = row
									this.dataModalCheck.show_ttd_intra_ranap = res.data.show_ttd_intra_ranap
									this.dataModalCheck.transferBedah = res.data.transferBedah

									setTimeout(() => {
									$(".sign-container canvas").attr("width", 400)
									$(".sign-container canvas").attr("height", 160)
									}, 200)
							})
					},
					submitPerawatPelaksana(){
							this.$refs['VFormSubmitPerawatPelaksana'].validate().then(success => {
									if (!success) {
											return this.$swal({
													icon: 'error',
													title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
											})
									} else {
											this.loadingOverlay = true
											let data = this.rowEdit
											data.type = 'assign-perawat-pelaksana'

											Gen.apiRest(
													"/do/" + 'RanapPasienPerawatan', {
													data: data
													},
													"POST"
											).then(res => {
													let resp = res.data
													this.loadingOverlay = false
													this.$swal({
															title: resp.message,
															icon: "success",
															confirmButtonText: 'Ok',
															allowOutsideClick: false,
															allowEscapeKey: false
													}).then(result => {
															if (result.value) {
																	this.openPerawatPelaksana = false
																	this.apiGet()
															}
													})
											})
									}
							})
					},
					openTTDIntra(row){
							this.loadingTTDIntra = true
							this.$bvModal.show('ttd_transfer_intra')
							Gen.apiRest('/get/RanapSerahTerimaPasien/form-ttd-regist', {
									params: {
											aranr_id: row.aranr_id,
									}
							}).then(res => {
									this.loadingTTDIntra = false
									this.dataModal = res.data.row

									setTimeout(() => {
									$(".sign-container canvas").attr("width", 400)
									$(".sign-container canvas").attr("height", 160)
									}, 200)
							}).catch(err => {
									this.loadingTTDIntra = false
									this.$swal({
											icon: 'error',
											text: err.response.data.message || 'Terjadi suatu kesalahan!'
									})
							})
					},
					closeModalTTD(){
							this.$set(this, 'dataModal', {})
							this.$bvModal.hide('ttd_transfer_intra')
					},
					doSubmitTTD(){
							this.output('ttd1','artipr_ttd_1', 'dataModal')
							this.output('ttd2','artipr_ttd_2', 'dataModal')

							if(!this.dataModal.artipr_ttd_1 || !this.dataModal.artipr_ttd_2){
									return this.$swal({
									icon: 'error',
									title: 'Mohon Isi Tanda Tangan'
									})
							}

							this.$bvModal.hide('list_ttd_intra')

							
							this.$refs.VFormTTD.validate().then(success => {
									if(!success) return
									
									this.loadingOverlay = true

									Gen.apiRest('/do/RanapSerahTerimaPasien', {
									data: {
											type: 'submit-ttd',
											...this.dataModal
									}
									}).then(res => {
									this.loadingOverlay = false
									this.$swal({
											icon: 'success',
											title: res.data.message,
									}).then(() => {
											this.closeModalTTD()
											this.apiGet()
									})
									}).catch(err => {
									this.loadingOverlay = false
									this.$swal({
											icon: 'error',
											message: err.response.data.message || 'Terjadi suatu kesalahan!'
									})
									})
							})
					},
					undoTTD(refs, field, payload = 'rowEdit') {
							this.$refs[refs].clearSignature()
							this[payload][field] = null
					},
					output(refs, field, payload = 'rowEdit') {
							let {
									isEmpty,
									data
							} = this.$refs[refs].saveSignature()
							if (!isEmpty) {
									this[payload][field] = data
							}
					},
					submitPPJP(){
							this.$refs['VFormSubmitPPJP'].validate().then(success=>{
									if(!success){
											return this.$swal({
													icon: 'error',
													title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
											})
									}else{
											this.loadingOverlay = true
											let data = this.rowEdit
											data.type = 'assign-perawat-ppjp'
										 
											Gen.apiRest(
													"/do/"+this.modulePage,
													{data: data}, 
													"POST"
											).then(res=>{
													let resp = res.data
													this.loadingOverlay = false
													this.$swal({
															title: resp.message,
															icon: "success",
															confirmButtonText: 'Ok',
															allowOutsideClick: false,
															allowEscapeKey: false
													}).then(result => {
															if(result.value){
																	this.openPPJP = false
																	this.apiGet()
															}
													})
											})
									}
							})
					},
					tugasKanPPJP(v){
							this.rowEdit = v
							let data = this.rowEdit
							data.type = 'get-data-alergi-diagnosa'
							this.loadingOverlay = true                 

							Gen.apiRest(
									"/do/"+this.modulePage,
									{data: data}, 
									"POST"
							).then(res=>{
									let resp = res.data
									this.openPPJP = true
									this.loadingOverlay = false     
									this.rowEdit.alergi = resp.alergi
									this.rowEdit.diagnosa = resp.diagnosa     

							})

					},
					setSlide(){
							const responsiveTable = document.querySelector('.table-responsive')
							if(responsiveTable){
									const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
									const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
									if(responsiveTableScroll){
											// responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
											Array.from(responsiveTableScrollActions).forEach(action => {
													action.addEventListener('click', e => {
															const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
															 
															responsiveTable.scroll({
																	left: scrollAmount,
																	behavior: 'smooth'
															})
													})
											})
									}
							}
					},
					doResetData(){
							this.doReset()
							this.dateRange =  {
									startDate: new Date(moment().subtract(6, 'days')),
									endDate: new Date(),
							}
							this.doConvertDate()
					},
					doRefreshData(){
							this.apiGet()
							window.scrollTo(0,0)
					},
					apiGetResume(){
							let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
							Gen.apiRest(
									"/get/"+this.modulePage+'/resume', 
									{
									params: Object.assign({}, paramsQuery||{})
									}
							).then(res=>{
									_.forEach(res.data, (v,k)=>{
											this.$set(this, k, v)
									})
							})
					},
					doConvertDate(){
							this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
							this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
					},
					doPageOne(){
							this.filter.page = 1
					},
					doFill(){
							this.doPageOne()
							this.doFilter()
					},
					updateValues(e){
							this.doConvertDate()
							this.doFill()
					},
					doSearch: _.debounce(function(){
							this.doPageOne()
							this.doFilter()
					},500),
					getConfigDynamic(master,value){
							let text = ''
							if(value){
									let index = (master||[]).findIndex(x => x.value == value)
									if(index !== -1){
											text = master[index]['text']
									}
							}
							return text
					},
					
					tugasKanPerawatPelaksana(v) {
							this.rowEdit = v
							let data = this.rowEdit
							data.type = 'get-data-alergi-diagnosa'
							this.loadingOverlay = true

							Gen.apiRest(
									"/do/" + 'RanapPasienPerawatan', {
									data: data
									},
									"POST"
							).then(res => {
									let resp = res.data
									this.openPerawatPelaksana = true
									this.loadingOverlay = false
									this.rowEdit.alergi = resp.alergi
									this.rowEdit.diagnosa = resp.diagnosa

									this.rowEdit.aranr_perawat_pelaksana = (this.rowEdit.aranr_perawat_pelaksana||[])
									let perawatPelaksanaRemovenull = this.rowEdit.aranr_perawat_pelaksana.filter(function(value) {
										return value != null
									})
									this.rowEdit.aranr_perawat_pelaksana = perawatPelaksanaRemovenull

									this.mPPJP = resp.mPPJP

							})
					},
			},
			filters: {
					date(val) {
							return val ? moment(val).format("D MMM YYYY") : ""
					}
			},
			watch:{
					$route(){
							this.apiGet()
							
							if(this.isList){
									this.apiGetResume()
							}
							
							setTimeout(()=>{
									if(this.isList){
											this.setSlide()
									}
							},1000)
					},
					'$route.params.pageSlug':function(){
							this.$set(this,'filter',{})
					},
					'openPPJP':function(v){
							if(!v){
									this.apiGet()
							}
					},
					'openPerawatPelaksana': function (v) {
							if (!v) {
									this.apiGet()
							}
					}
			}
	}
</script>